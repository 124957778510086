<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav flex flex-col">
  <div class="sidenav-toolbar flex-none flex items-center custom-sidebar-header">
    <div><img src="assets/img/corp/workout-logo.png" class="select-none flex-none"></div>
    <!-- <h2 class="title ltr:pl-4 rtl:pr-4 select-none flex-auto">Workout CMS</h2> -->
    <button (click)="toggleCollapse()"
            *ngIf="showCollapsePin$ | async"
            class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
            mat-icon-button
            type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="14px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="14px"></mat-icon>
    </button>
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="sidenav-items">
      <vex-sidenav-item *ngFor="let item of items; trackBy: trackByRoute"
                        [item]="item"
                        [level]="0"></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
