import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/services/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { Style, StyleService } from '../@vex/services/style.service';
import { ConfigName } from '../@vex/interfaces/config-name.model';

import icLayers from '@iconify/icons-ic/twotone-layers';
import icHome from '@iconify/icons-ic/home';
import icDumbbell from '@iconify/icons-fa-solid/dumbbell';
import icLiveTv from '@iconify/icons-ic/live-tv';
import icSubscribers from '@iconify/icons-ic/group';
import { UserGlobals } from './user_globals';

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'vex';

  constructor(private configService: ConfigService,
    private styleService: StyleService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService,
    public user_globals: UserGlobals) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    showConfigButton: false,
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */
    this.route.queryParamMap.pipe(
      map(queryParamMap => queryParamMap.has('rtl') && coerceBooleanProperty(queryParamMap.get('rtl'))),
    ).subscribe(isRtl => {
      this.document.body.dir = isRtl ? 'rtl' : 'ltr';
      this.configService.updateConfig({
        rtl: isRtl
      });
    });

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('layout'))
    ).subscribe(queryParamMap => this.configService.setConfig(queryParamMap.get('layout') as ConfigName));

    this.route.queryParamMap.pipe(
      filter(queryParamMap => queryParamMap.has('style'))
    ).subscribe(queryParamMap => this.styleService.setStyle(queryParamMap.get('style') as Style));


    this.navigationService.items = [
      {
        type: 'link',
        label: 'Inicio',
        route: '/',
        icon: icHome,
        routerLinkActiveOptions: {
          exact: true
        }
      },
      {
        type: 'dropdown',
        label: 'Workout',
        icon: icDumbbell,
        children: [
          {
            type: 'link',
            label: 'Tipos de entreno',
            route: 'program-types'
          },
          {
            type: 'link',
            label: 'Entrenos programados',
            route: 'programs',
          }
        ]
      },
      {
        type: 'dropdown',
        label: 'Media',
        icon: icLiveTv,
        children: [
          {
            type: 'link',
            label: 'Últimas noticias',
            route: 'news-entries'
          },
          {
            type: 'link',
            label: 'Galería de imágenes',
            route: 'image-gallery'
          },
          {
            type: 'link',
            label: 'Banners de bienvenida',
            route: 'banners'
          }
        ]
      },
      {
        type: 'link',
        label: 'Mis clientes',
        icon: icSubscribers,
        route: 'customers'
      }
    ]
  }

  async ngOnInit(): Promise<void> {
    this.user_globals.userIsChecked();
  }
}
