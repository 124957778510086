import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomLayoutComponent } from "./custom-layout/custom-layout.component";

const routes: Routes = [
  {
    path: "",
    component: CustomLayoutComponent,
    children: [
      {
        path: "home",
        redirectTo: "/",
      },
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
        data: {
          toolbarShadowEnabled: true,
        }
      },
      {
        path: "news-entries",
        loadChildren: () =>
          import("./pages/sections/news/list-news/list-news.module").then((m) => m.ListNewsModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "edit-entry",
        loadChildren: () =>
          import("./pages/sections/news/conf-news/conf-news.module").then((m) => m.ConfNewsModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "programs",
        loadChildren: () =>
          import("./pages/sections/training/list-trainings/list-trainings.module").then((m) => m.ListTrainingsModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "program-types",
        loadChildren: () =>
          import("./pages/sections/training/list-types/list-types.module").then((m) => m.ListTypesModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "image-gallery",
        loadChildren: () =>
          import("./pages/sections/gallery/gallery/gallery-routing.module").then((m) =>m.GalleryRoutingModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "banners",
        loadChildren: () =>
          import("./pages/sections/banners/banners-routing.module").then((m) =>m.BannersRoutingModule),
        data: {
          toolbarShadowEnabled: true,
        },
      },
      {
        path: "customers",
        loadChildren: () =>
          import("./pages/sections/customers/customers-list/customers-list.module").then((m) => m.CustomersListModule),
        data: {
          toolbarShadowEnabled: true,
        },
      }
    ]
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login/login.module").then((m) => m.LoginModule),
    data: {
      toolbarShadowEnabled: true
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "corrected",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
