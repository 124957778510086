import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ConfScheduleComponent } from './pages/sections/training/conf-trainings/conf-schedule/conf-schedule.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { CustomersListComponent } from './pages/sections/customers/customers-list/customers-list.component';
import { ConfGalleryDialogComponent } from './pages/sections/gallery/conf-gallery-dialog/conf-gallery-dialog.component';
import { UserGlobals } from './user_globals';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    MatDialogModule,
    MatSelectModule,
    MatOptionModule,

    // Vex
    VexModule,
    CustomLayoutModule
  ],
  providers: [UserGlobals],
  bootstrap: [AppComponent]
})
export class AppModule { }
